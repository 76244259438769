<template>
  <div class="position-relative">
    <div class="legend" :id="'legend_' + id" :style="'--id:' + id"></div>
    <div class="position-relative chart-container">
      <canvas :id="id" height="300"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  props: {
    id: {
      required: true,
      type: String,
    },
    chartData: {
      required: true,
      type: Array,
    },
    label: {
      required: true,
      type: Array,
    },
    tick: {
      required: false,
    },
  },

  mounted() {
    this.initChart();
  },
  methods: {
    formatNumber(num, precision = 0) {
      const map = [
        { suffix: "M", threshold: 1e6, multiple: 1 },
        { suffix: "K", threshold: 1e4, multiple: 10 },
        { suffix: "K", threshold: 1e3, multiple: 1 },
        { suffix: "", threshold: 1, multiple: 1 },
      ];

      const found = map.find((x) => Math.abs(num) >= x.threshold);

      if (found) {
        if (found.threshold == 1) {
          return num.toLocaleString();
        }
        const formatted =
          (num / found.threshold) * found.multiple + found.suffix;
        return formatted;
      }

      return num;
    },
    addCommas(nStr) {
      var x, x1, x2;

      nStr += "";
      x = nStr.split(".");
      x1 = x[0];
      x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "," + "$2");
      }
      return x1 + x2;
    },
    initChart() {
      // if(this.tick)

      var ticksDate = this.ChartTicked(this.tick || 0);

      var ctx = document.getElementById(this.id);
      var that = this;
      var myChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: this.label,
          datasets: this.chartData,
        },
        options: {
          tooltips: {
            mode: "index",
            intersect: false,
            callbacks: {
              title: function (tooltipItem, data) {
                return `${data.datasets[tooltipItem[0].datasetIndex].label} (${
                  data.labels[tooltipItem[0].index]
                })`;
              },
              // label: function (tooltipItem, data) {
              //   var datasetLabel =
              //     data.datasets[tooltipItem.datasetIndex].label || "Other";
              //   return datasetLabel + ": " + that.addCommas(tooltipItem.value);
              // },
            },
          },
          hover: {
            mode: "index",
            intersect: false,
          },
          responsive: true,
          legend: {
            display: false,
          },

          interaction: {
            mode: "index",
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  color: "rgba(0, 0, 0, 0)",
                },
                scaleLabel: {
                  display: true,
                  fontStyle: "bold",
                  labelString: "Number of Persons",
                  fontFamily: "Kanit-Regular",
                },
                ticks: {
                  callback: function (label) {
                    return that.addCommas(that.formatNumber(label));
                  },
                  min: 0,
                  autoSkip: true,
                  suggestedMin: 0,
                  beginAtZero: true,
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  color: "rgba(0, 0, 0, 0)",
                },
                ticks: {
                  autoSkip: false,
                  callback: function (val, index) {
                    return index % ticksDate === 0
                      ? that.$moment(val, "DD/MM/YYYY").format("DD MMM")
                      : "";
                  },
                  min: that.label[0],
                  max: that.label[that.label.length - 1],
                },
              },
            ],
          },
        },
      });

      // document.getElementById("legend_" + this.id).innerHTML =
      //   myChart.generateLegend();
    },
  },
};
</script>

<style lang="scss">
.legend {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.chart-container {
  // margin-right: 100px;
}
ul {
  padding-inline-start: 0px !important;
}
@media screen and (max-width: 600px) {
  .chart-container {
    margin-bottom: 20px;
    margin-right: unset;
  }
  .legend {
    bottom: 0;
    right: 50%;
    top: 100%;

    transform: translate(50%, -20px);
    ul {
      flex-direction: row !important;
      gap: 5px;
      li {
        margin-bottom: 0 !important;
      }
      span {
        font-size: 9px !important;
        width: max-content;
        max-width: 80px !important;
      }
    }
  }
}
</style>
