<template>
  <canvas :id="id" height="300"></canvas>
</template>

<script>
import Chart from "chart.js";
export default {
  props: {
    id: {
      required: true,
      type: String,
    },

    chartRange: {
      required: true,
      type: Object,
    },
    titleLeft: {
      required: false,
      type: String,
    },
    titleBottom: {
      required: false,
      type: String,
    },
  },
  mounted() {
    this.initChart();
  },
  methods: {
    addCommas(nStr) {
      var x, x1, x2;

      nStr += "";
      x = nStr.split(".");
      x1 = x[0];
      x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "," + "$2");
      }
      return x1 + x2;
    },
    initChart() {
      var ctx = document.getElementById(this.id);
      var ticksDate = this.ChartTicked(Math.max(...this.chartRange.sumCount));

      var data = {
        labels: this.chartRange.label,
        datasets: [
          {
            label: "Min (1)",
            type: "bar",
            fill: false,
            backgroundColor: "#E91C2B",
            data: this.chartRange.count.min,
          },
          {
            label: "Average Min (2)",
            type: "bar",
            fill: false,
            backgroundColor: "#F48A1D",
            data: this.chartRange.count.avg_min,
          },
          {
            label: "Average (3)",
            type: "bar",
            fill: false,
            backgroundColor: "#EACF2A",
            data: this.chartRange.count.avg,
          },
          {
            label: "Average Max (4)",
            type: "bar",
            fill: false,
            backgroundColor: "#0BA350",
            data: this.chartRange.count.avg_max,
          },
          {
            label: "Max (5)",
            type: "bar",
            fill: false,
            backgroundColor: "#3483B1",
            data: this.chartRange.count.max,
          },
        ],
      };
      var that = this;
      var myChart = new Chart(ctx, {
        type: "bar",
        data: data,
        options: {
          tooltips: {
            mode: "index",
            intersect: false,
            callbacks: {
              title: function (tooltipItem, data) {
                return `(${data.labels[tooltipItem[0].index]})`;
              },
              label: function (tooltipItem, data) {
                // if (data.datasets[tooltipItem.datasetIndex].label) {
                var datasetLabel =
                  data.datasets[tooltipItem.datasetIndex].label || "Total";
                return datasetLabel + ": " + that.addCommas(tooltipItem.value);
                // }
              },
            },
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                // gridLines: {
                //   display: false,
                // },
                scaleLabel: {
                  display: true,
                  labelString: this.titleBottom,
                  fontStyle: "bold",
                  fontFamily: "Kanit-Regular",
                },
              },
            ],
            yAxes: [
              {
                type: "logarithmic",

                ticks: {
                  callback: function (label, index, labels) {
                    // console.log(label, index, labels);
                    // if (index > 3 && index < labels.length / 2 + 2) return;
                    // if (
                    //   index > labels.length / 2 &&
                    //   index % 3 == 0 &&
                    //   label != 0
                    // )
                    //   return;

                    if (index == 0) return that.addCommas(label);
                    if (index % 4 == 0) return that.addCommas(label);
                    if (label == 0) return that.addCommas(label);
                  },
                  stepSize: ticksDate,
                  beginAtZero: true,
                  autoSkip: true,
                  suggestedMin: 0,
                },
                // type: "linear",
              },
            ],
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: "bottom",
            labels: { boxWidth: 10, boxHeight: 10 },
          },
        },
      });
      // console.log(myChart);
    },
  },
};
</script>

<style></style>
