<template>
  <div class="bg-white pt-3">
    <StatPanel :column="statColumn" :data="stat" :isLoading="statLoading" />
    <b-container>
      <b-row class="mt-3">
        <b-col cols="12">
          <b-card class="shadow-sm border-none text-black rounded-lg mb-3">
            <div class="mb-2 content-between">
              <div class="chart-title-name">Member Tier</div>
              <div class="d-flex align-items-center" style="column-gap: 5px">
                <div
                  class="f-14 underline cursor-pointer"
                  v-for="chartFilter of chartList[6].filter"
                  :key="chartFilter + '-' + chartList[6].name"
                  @click="handleChartFilter(chartFilter, chartList[6])"
                >
                  <span class="text-highlight">{{ chartFilter }}</span>
                </div>
              </div>
            </div>
            <b-table
              fixed
              striped
              hover
              :fields="fields.member_tier"
              :items="member_tier.items"
              :busy="member_tier.isBusy"
              show-empty
              foot-clone
              no-footer-sorting
              empty-text="No matching records found"
              @sort-changed="memberTierSorting"
              :sort-by.sync="member_tier.filter.sortBy"
              no-local-sorting
              :sort-desc.sync="member_tier.filter.sortDesc"
            >
              <template v-slot:cell(member)="data">
                <span>
                  {{ data.item.member | numeral("0,0") }}
                  ({{ data.item.percen_member }}%)
                </span>
              </template>
              <template v-slot:cell(atv)="data">
                <span>
                  {{ data.item.atv | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(revenue)="data">
                <span>
                  {{ data.item.revenue | numeral("0,0.00") }}
                  ({{ data.item.percen_revenue }}%)
                </span>
              </template>
              <template v-slot:cell(bills)="data">
                <span>
                  {{ data.item.bills | numeral("0,0") }}
                  ({{ data.item.percen_bills }}%)
                </span>
              </template>
              <template v-slot:cell(units)="data">
                <span>
                  {{ data.item.units | numeral("0,0") }}
                  ({{ data.item.percen_units }}%)
                </span>
              </template>

              <template v-slot:foot()="data">
                <span
                  v-if="stat.total_member_tier_table[data.field.key]"
                  class="text-center"
                >
                  <span
                    v-if="
                      data.field.key == 'bills' || data.field.key == 'units'
                    "
                  >
                    {{
                      stat.total_member_tier_table[data.field.key]
                        | numeral("0,0")
                    }}</span
                  >
                  <span v-else>
                    {{
                      stat.total_member_tier_table[data.field.key]
                        | numeral("0,0.00")
                    }}</span
                  >
                </span>
                <span v-else>
                  <div class="text-left px-2" v-if="data.field.text">
                    {{ data.field.text }}
                  </div>
                </span>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          lg="6"
          v-for="(chart, index) in chartList"
          :key="chart.name"
          :class="['mb-3 resize', { 'd-none': !chart.isChart }]"
        >
          <b-card
            class="shadow-sm border-none text-black rounded-lg h-100"
            v-if="chart.isChart"
          >
            <div>
              <div :class="['mr-4 mb-3', index == 0 ? 'ml-1' : 'ml-4']">
                <div class="content-between">
                  <div :class="['chart-title-name']">
                    {{ chart.name }}
                  </div>
                  <div class="d-flex" style="column-gap: 5px">
                    <div
                      :class="`f-14 underline cursor-pointer`"
                      v-for="chartFilter in chart.filter"
                      :key="chartFilter + '-' + chart.name"
                      @click="handleChartFilter(chartFilter, chart)"
                    >
                      <span
                        :class="`${
                          chart.filterValue == chartFilter ||
                          chartFilter == 'Export'
                            ? 'text-highlight'
                            : 'text-gray'
                        }`"
                        >{{ chartFilter }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <template v-if="chart.isFetch">
                <ChartLoading
                  :id="'loading-' + chart.name"
                  :type="chart.type"
                />
              </template>
              <template v-else>
                <LineChart
                  v-if="index == 0"
                  :ref="`chart-${index}`"
                  :id="chart.name"
                  :chartData="chart.data"
                  class="mt-auto"
                  :text="chart.text"
                  :label="chart.label"
                  :filterValue="chart.filterValue"
                  :filter="filter"
                  xText="Date"
                  :isStacked="chart.isStacked"
                />
                <BasketChart
                  v-else
                  :id="chart.name"
                  :chartData="chart.data"
                  :text="chart.text"
                  class="mt-auto"
                  :hideLegend="chart.hideLegend"
                  :label="chart.label"
                  :type="chart.type"
                  :filterValue="chart.filterValue"
                  :xText="chart.xText"
                  :isStacked="chart.isStacked"
                />
              </template>
            </div>
          </b-card>
        </b-col>
        <b-col cols="12">
          <b-card class="shadow-sm border-none text-black rounded-lg">
            <div class="mb-2 content-between">
              <div class="chart-title-name">Product</div>
              <div class="d-flex align-items-center" style="column-gap: 5px">
                <div
                  class="f-14 underline cursor-pointer"
                  v-for="chartFilter of chartList[4].filter"
                  :key="chartFilter + '-' + chartList[4].name"
                  @click="handleChartFilter(chartFilter, chartList[4])"
                >
                  <span class="text-highlight">{{ chartFilter }}</span>
                </div>
              </div>
            </div>
            <b-table
              fixed
              striped
              hover
              :fields="fields.product"
              :items="product.items"
              :busy="product.isBusy"
              show-empty
              foot-clone
              no-footer-sorting
              empty-text="No matching records found"
              @sort-changed="sortingChanged"
              :sort-by.sync="product.filter.sortBy"
              no-local-sorting
              :sort-desc.sync="product.filter.sortDesc"
            >
              <template v-slot:cell(totalCustomer)="data">
                <span>
                  {{ data.item.totalCustomer | numeral("0,0") }}
                  ({{
                    ((data.item.totalCustomer /
                      stat.total_product_table["totalCustomer"]) *
                      100)
                      | numeral("0.00")
                  }}%)
                </span>
              </template>
              <template v-slot:cell(totalSales)="data">
                <span>
                  {{ data.item.totalSales | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(totalQuantity)="data">
                <span>
                  {{ data.item.totalQuantity | numeral("0,0") }}
                </span>
              </template>
              <template v-slot:cell(atv)="data">
                <span>
                  {{ data.item.atv | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(percenSalesContribution)="data">
                <span>
                  {{
                    ((data.item.totalSales /
                      stat.total_product_table["totalSales"]) *
                      100)
                      | numeral("0.00")
                  }}%
                </span>
              </template>
              <template v-slot:foot()="data">
                <span
                  v-if="stat.total_product_table[data.field.key]"
                  class="text-center"
                >
                  <span
                    v-if="
                      data.field.key == 'totalCustomer' ||
                      data.field.key == 'totalQuantity'
                    "
                  >
                    {{
                      stat.total_product_table[data.field.key] | numeral("0,0")
                    }}</span
                  >
                  <span v-else>
                    {{
                      stat.total_product_table[data.field.key]
                        | numeral("0,0.00")
                    }}</span
                  >
                </span>
                <span v-else>
                  <div class="text-left px-2" v-if="data.field.text">
                    {{ data.field.text }}
                  </div></span
                >
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
            <Pagination
              @handleChangeTake="handleChangeTakeProduct"
              @pagination="paginationProduct"
              :filter="product.filter"
              :rows="product.rows"
              :limit="2"
            />
          </b-card>
        </b-col>

        <b-col cols="12">
          <b-card class="shadow-sm border-none text-black rounded-lg mb-3">
            <div class="mb-2 content-between">
              <div class="chart-title-name">Branch</div>
              <div class="d-flex align-items-center" style="column-gap: 5px">
                <div
                  class="f-14 underline cursor-pointer"
                  v-for="chartFilter of chartList[5].filter"
                  :key="chartFilter + '-' + chartList[5].name"
                  @click="handleChartFilter(chartFilter, chartList[5])"
                >
                  <span class="text-highlight">{{ chartFilter }}</span>
                </div>
              </div>
            </div>
            <b-table
              fixed
              striped
              hover
              :fields="fields.store"
              :items="store.items"
              :busy="store.isBusy"
              show-empty
              foot-clone
              no-footer-sorting
              empty-text="No matching records found"
              @sort-changed="storeSorting"
              :sort-by.sync="store.filter.sortBy"
              no-local-sorting
              :sort-desc.sync="store.filter.sortDesc"
            >
              <template v-slot:cell(revenue)="data">
                <span>
                  {{ data.item.revenue | numeral("0,0.00") }}
                  ({{
                    ((data.item.revenue / stat.total_store_table["revenue"]) *
                      100)
                      | numeral("0.00")
                  }}%)
                </span>
              </template>
              <template v-slot:cell(branchATV)="data">
                <span>
                  {{ data.item.branchATV | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(branchUPT)="data">
                <span>
                  {{ data.item.branchUPT | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(units)="data">
                <span>
                  {{ data.item.units | numeral("0,0") }}
                  ({{
                    ((data.item.units / stat.total_store_table["units"]) * 100)
                      | numeral("0.00")
                  }}%)
                </span>
              </template>
              <template v-slot:cell(bills)="data">
                <span>
                  {{ data.item.bills | numeral("0,0") }}
                  ({{
                    ((data.item.bills / stat.total_store_table["bills"]) * 100)
                      | numeral("0.00")
                  }}%)
                </span>
              </template>
              <template v-slot:cell(newMembers)="data">
                <span>
                  {{ data.item.newMembers | numeral("0,0") }}
                  ({{
                    ((data.item.newMembers /
                      stat.total_store_table["members"]) *
                      100)
                      | numeral("0.00")
                  }}%)
                </span>
              </template>
              <template v-slot:foot()="data">
                <span
                  v-if="stat.total_store_table[data.field.key]"
                  class="text-center"
                >
                  <span
                    v-if="
                      data.field.key == 'units' || data.field.key == 'bills'
                    "
                  >
                    {{
                      stat.total_store_table[data.field.key] | numeral("0,0")
                    }}</span
                  >

                  <span v-else>
                    {{
                      stat.total_store_table[data.field.key] | numeral("0,0.00")
                    }}</span
                  >
                </span>
                <span v-else-if="data.field.key == 'newMembers'">{{
                  stat.total_store_table["members"] | numeral("0,0")
                }}</span>
                <span v-else>
                  <div class="text-left px-2" v-if="data.field.text">
                    {{ data.field.text }}
                  </div>
                </span>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
            <Pagination
              @handleChangeTake="handleChangeTakeStore"
              @pagination="paginationStore"
              :filter="store.filter"
              :rows="store.rows"
              class="p-2"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <SideBarFilter
      id="firstPurchaseFilter"
      ref="firstPurchaseFilter"
      :filter="filter"
      :placeholder="'Search Name'"
      @clearFilter="clearFilter"
      @searchAll="filterActions"
      :hideStatusFilter="false"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <FilterCustomDate
          customText="Transaction Date"
          id="6"
          :from="filter.TransactionDateFrom"
          :to="filter.TransactionDateTo"
          :type="filter.LastType"
          @update:type="(value) => (filter.LastType = value)"
          @update:from="(value) => (filter.TransactionDateFrom = value)"
          @update:to="(value) => (filter.TransactionDateTo = value)"
        />

        <!-- <div class="mb-3 input-select">
          <InputSelect
            title="Transaction Date"
            name="transaction-date"
            class="mt-2"
            v-model="filter.LastType"
            :options="options.dateType"
            valueField="value"
            textField="text"
          >
            <template v-slot:option-first>
              <b-form-select-option value="" disabled
                >-- Select Transaction Date--</b-form-select-option
              >
            </template>
          </InputSelect>
        </div>
        <div v-if="filter.LastType == 0" class="mb-3">
          <InputDatePickerFilter
            textFloat="Transaction Date From"
            name="transaction-date-from"
            ref="transaction-date-from"
            :max-datetime="filter.TransactionDateTo || initFilterDate.MaxDate"
            :min-datetime="initFilterDate.MinDate"
            :value="filter.TransactionDateFrom"
            @input="(val) => (filter.TransactionDateFrom = val)"
          />
        </div>
        <div v-if="filter.LastType == 0" class="mb-3">
          <InputDatePickerFilter
            textFloat="Transaction Date To"
            name="transaction-date-to"
            ref="transaction-date-to"
            :max-datetime="initFilterDate.MaxDate"
            :min-datetime="filter.TransactionDateFrom || initFilterDate.MinDate"
            :value="filter.TransactionDateTo"
            @input="(val) => (filter.TransactionDateTo = val)"
          />
        </div> -->
        <div class="mb-3">
          <MultiSelect
            used="channel"
            textFloat="Channel"
            :options="options.channelTypes"
            textField="channelName"
            valueField="id"
            placeholder="Select Channel"
            v-model="filter.ChannelFilter"
            :value="filter.ChannelFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            :isLoading="!isFilterProduct"
            used="product"
            textFloat="Barcode / Product"
            :options="options.product"
            textField="productName"
            valueField="id"
            placeholder="Select Barcode / Product"
            v-model="filter.ProductFilter"
            :value="filter.ProductFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            used="branch"
            textFloat="Purchase Branch"
            :options="options.branches"
            textField="branchName"
            valueField="id"
            placeholder="Select Purchase Branch"
            v-model="filter.BranchFilter"
            :value="filter.BranchFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            used="age"
            textFloat="Age Group"
            :options="options.ageGroups"
            textField="ageGroupName"
            valueField="id"
            placeholder="Select Age Group"
            v-model="filter.AgeGroupFilter"
            :value="filter.AgeGroupFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            used="gender"
            textFloat="Gender"
            :options="options.genderModels"
            textField="genderGroupName"
            valueField="id"
            placeholder="Select Gender"
            v-model="filter.GenderFilter"
            :value="filter.GenderFilter"
          />
        </div>
        <div class="my-3">
          <MultiSelect
            used="tier"
            textFloat="Member tier"
            :options="options.memberTiers"
            textField="memberTierName"
            valueField="id"
            placeholder="Select Member tier"
            v-model="filter.MemberTierFilter"
            :value="filter.MemberTierFilter"
          />
        </div>
      </template>
    </SideBarFilter>
    <ModalInputEmail
      ref="ModalInputEmail"
      :form="filterExportFile"
      @closeModal="clearExportFilter"
      @changeEmail="(val) => (filterExportFile.email = val)"
      @submit="exportFile(filterExportFile.mode, filterExportFile.isRe)"
    />
  </div>
</template>

<script>
import moment from "moment";
import LineChart from "./LineChart";
import BasketChart from "./BasketChart";
import ChartLoading from "@/components/loading/ChartLoading";
import Multiselect from "vue-multiselect";
import ModalInputEmail from "@/components/customer/ModalInputEmail";

function getRandomColor() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return "rgb(" + r + "," + g + "," + b + ")";
}
export default {
  components: {
    Multiselect,
    LineChart,
    BasketChart,
    ChartLoading,

    ModalInputEmail,
  },
  data() {
    return {
      // isFilterLoading: true,
      filterExportFile: {
        email: "",
        mode: "",
      },
      statLoading: true,
      statColumn: [
        {
          name: "Total Bill",
          key: "total_bill",
          isPercent: false,
          isDecimal: false,
        },

        {
          name: "Total Sales",
          key: "total_sales",
          isPercent: false,
          isDecimal: false,
        },
        {
          name: "Total Unit",
          key: "total_unit",
          isPercent: false,
          isDecimal: false,
        },
        {
          name: "ATV",
          key: "atv",
          isPercent: false,
          isDecimal: true,
        },
        {
          name: "UPT",
          key: "upt",
          isPercent: false,
          isDecimal: true,
        },
      ],
      filter: {
        ChannelFilter: this.$store.state.filter.channel,
        BranchFilter: this.$store.state.filter.branch,
        AgeGroupFilter: this.$store.state.filter.age,
        GenderFilter: this.$store.state.filter.gender,
        ProductFilter: this.$store.state.filter.product,
        MemberTierFilter: this.$store.state.filter.tier,
        TransactionDateFrom: this.$store.state.filter.fr,
        TransactionDateTo: this.$store.state.filter.to,
        TypeDateFilter: 2,
        LastType: 0,
        LastType: this.$store.state.filter.dateType,
      },

      options: {
        channelTypes: [],
        branches: [],
        ageGroups: [],
        genderModels: [],
        dateType: [
          { text: "Last 7 Days", value: 7 },
          { text: "Last 30 Days", value: 30 },
          { text: "Last 365 Days", value: 365 },
          { text: "Custom", value: 0 },
        ],
        product: [],
        memberTiers: [],
      },
      chartList: [
        {
          name: "First Time Purchase Customer By Date",
          filterValue: "Month",
          text: "Total Customers",
          filter: ["Day", "Month", "Year", "Export"],
          label: [],
          data: [],
          get: () => this.getCustomer(),
          isFetch: true,
          exportMode: 1,
          isChart: true,
          xText: "Date",
        },
        {
          name: "Revenue",
          filterValue: "Month",
          text: "Total Revenue",
          filter: ["Day", "Month", "Year", "Export"],
          label: [],
          data: [],
          get: () => this.getRevenue(),
          type: "bar",
          isFetch: true,
          exportMode: 2,
          isChart: true,
          isStacked: true,
          xText: "Date",
        },
        {
          name: "Age",
          filterValue: "",
          text: "Number Of Customer",
          filter: ["Export"],
          label: [],
          data: [],
          get: () => this.getAge(),
          type: "bar",
          isFetch: true,
          exportMode: 3,
          isChart: true,
          xText: "Age Group",
          hideLegend: true,
        },
        {
          name: "Gender",
          filterValue: "",
          text: "Number Of Customer",
          filter: ["Export"],
          label: [],
          data: [],
          get: () => this.getGender(),
          type: "bar",
          isFetch: true,
          exportMode: 4,
          isChart: true,
          xText: "Gender",
          hideLegend: true,
        },
        {
          name: "Product",
          filter: ["Export"],
          exportMode: 5,
        },
        {
          name: "Store",
          filter: ["Export"],
          exportMode: 6,
        },
        {
          name: "Member Tiers",
          filter: ["Export"],
          exportMode: 7,
        },
      ],
      fields: {
        store: [
          {
            key: "branchName",
            label: "Store",
            sortable: true,
            class: "w-3",
            text: "Total",
          },
          {
            key: "channelName",
            label: "Channel",
            sortable: true,
            class: "w-3",
          },
          {
            key: "revenue",
            label: "Total Sales",
            sortable: true,
            class: "w-2",
          },
          {
            key: "branchATV",
            label: "ATV",
            sortable: true,
            class: "w-2",
          },
          {
            key: "branchUPT",
            label: "UPT",
            sortable: true,
            class: "w-1",
          },
          {
            key: "units",
            label: "Units",
            sortable: true,
            class: "w-1",
          },
          {
            key: "bills",
            label: "Bills",
            sortable: true,
            class: "w-1",
          },
          {
            key: "newMembers",
            label: "New Members",
            sortable: true,
            class: "w-2",
          },
        ],
        product: [
          {
            key: "barcode",
            label: "Barcode",
            class: "w-2",
            sortable: true,
            text: "Total",
          },
          {
            key: "productName",
            label: "Name",
            thClass: "w-5",
            sortable: true,
          },
          {
            key: "totalCustomer",
            label: "% Customer",
            class: "w-2",
            sortable: true,
          },
          {
            key: "totalSales",
            label: "Total Sales",
            class: "w-2",
            sortable: true,
          },
          {
            key: "totalQuantity",
            label: "Total Quantity",
            class: "w-2",
            sortable: true,
          },
          {
            key: "atv",
            label: "ATV",
            class: "w-1",
            sortable: true,
          },
          {
            key: "percenSalesContribution",
            label: "% Sales Contribution",
            class: "w-2",
            sortable: true,
          },
        ],
        member_tier: [
          {
            key: "tier_name",
            label: "Member Tier Name",
            class: "w-2",
            sortable: true,
            text: "Total",
          },
          {
            key: "member",
            label: "Number Member",
            thClass: "w-5",
            sortable: true,
          },
          {
            key: "atv",
            label: "ATV",
            class: "w-2",
            sortable: true,
          },
          {
            key: "revenue",
            label: "Sales",
            class: "w-2",
            sortable: true,
          },
          {
            key: "bills",
            label: "Bills",
            class: "w-2",
            sortable: true,
          },

          {
            key: "units",
            label: "Units",
            class: "w-2",
            sortable: true,
          },
        ],
      },
      product: {
        items: [],
        isBusy: false,
        rows: 0,
        filter: {
          search: "",
          page: 1,
          take: 5,
          SortColumnId: 0,
          SortType: true,
          sortBy: "",
          sortDesc: "",
        },
      },
      store: {
        items: [],
        isBusy: false,
        rows: 0,
        filter: {
          search: "",
          page: 1,
          take: 5,
          SortColumnId: 0,
          SortType: true,
          sortBy: "",
          sortDesc: "",
        },
      },
      member_tier: {
        items: [],
        isBusy: false,
        rows: 0,
        filter: {
          search: "",
          page: 1,
          take: 5,
          SortColumnId: 0,
          SortType: true,
          sortBy: "",
          sortDesc: "",
        },
      },
      stat: {
        new_member: 0,
        member: 0,
        percent_new_member: 0,
        totalBill: 0,
        totalRevenue: 0,
        totalUnit: 0,
        atv: 0,
        upt: 0,
        total_store_table: {},
        total_member_tier_table: {
          member: 0,
          percen_member: 0,
          revenue: 0,
          percen_revenue: 0,
          atv: 0,
          bills: 0,
          percen_bills: 0,
          units: 0,
          percen_units: 0,
        },
        total_product_table: {
          totalCustomer: 0,
          percenCustomer: 0,
          totalSales: 0,
          totalQuantity: 0,
          atv: 0,
          percenSaleContribution: 0,
        },
      },
      isFilterProduct: true,
      initFilterDate: {
        MinDate: "",
        MaxDate: "",
        TransactionDateFrom: "",
        TransactionDateTo: "",
      },
      statLoading: true,
    };
  },
  async mounted() {
    this.initDateFilter();
    this.getFilter();
    this.getProductFilter();
    this.getDefault();
    // await this.getData();
  },

  methods: {
    initDateFilter() {
      let toDay = moment().format("YYYY-MM-DDT00:00:00");
      this.initFilterDate.TransactionDateFrom = moment(toDay).day(-7).format();
      this.initFilterDate.TransactionDateTo = moment(toDay).format();
      // this.initFilterDate.MinDate = moment(toDay).day(-365).format();
      this.initFilterDate.MaxDate = toDay;
      this.filter.TransactionDateFrom = this.initFilterDate.TransactionDateFrom;
      this.filter.TransactionDateTo = this.initFilterDate.TransactionDateTo;
    },
    exportModal(mode) {
      this.filterExportFile.mode = mode;
      this.$refs.ModalInputEmail.show();
    },
    async getData() {
      return await Promise.all([
        this.getStat(),
        this.getCustomer(),
        this.getRevenue(),
        this.getAge(),
        this.getGender(),
      ]);
    },
    async getDefault() {
      const res = await this.$report.post(
        `/DashBoard/first_purchase/default`,
        this.filter
      );
      await this.getStat(true, res.data.detail.result_static);
      this.getProduct();
      this.getMemberTier();
      this.getStore();
      this.getCustomer(true, res.data.detail.result_customer);
      this.getRevenue(true, res.data.detail.result_revenue);
      this.getAge(true, res.data.detail.result_age);
      this.getGender(true, res.data.detail.result_gender);
    },
    handleDateFormatLabel(type, date) {
      let value = "";
      if (type === 1) value = moment(date).subtract(1, "years").format("YYYY");
      else if (type === 2)
        value = moment(date, "MM/YYYY").subtract(1, "months").format("MM/YYYY");
      else
        value = moment(date, "DD/MM/YYYY")
          .subtract(1, "days")
          .format("DD/MM/YYYY");

      return value;
    },
    async getCustomer(has, data) {
      this.chartList[0].isFetch = true;
      var respData = data;
      if (!has) {
        const res = await this.$report.post(
          `/DashBoard/first_purchase/graph_customer`,
          this.filter
        );
        respData = res.data.detail;
      }

      let label = [];
      var dataSet = [];

      if (this.filter.GraphType == 3) {
        label = await respData.map((el) =>
          moment(el.filter_date).format("DD/MM/YYYY")
        );
      } else {
        label = await respData.map((el) => el.filter_date);
      }

      var color = getRandomColor();
      var colorNew = getRandomColor();
      dataSet.push(
        {
          label: "Total Customers",
          data: [],
          borderColor: color,
          backgroundColor: color,
          fill: false,
        },
        {
          label: "First Time Purchase Customer",
          data: [],
          borderColor: colorNew,
          backgroundColor: colorNew,
          fill: false,
        }
      );

      for (const x of respData) {
        dataSet[0].data.push(x.member);
        dataSet[1].data.push(x.new_member);
      }

      if (label.length === 1) {
        let fDate = await this.handleDateFormatLabel(
          this.filter.TypeDateFilter,
          label[0]
        );
        label = await [fDate, ...label];
        await dataSet.forEach((ele) => {
          ele.data.unshift(0);
        });
      }

      this.chartList[0].label = label;
      this.chartList[0].data = dataSet;
      this.chartList[0].isFetch = false;
    },
    async getRevenue(has, data) {
      this.chartList[1].isFetch = true;

      var respData = data;
      if (!has) {
        const res = await this.$report.post(
          `/DashBoard/first_purchase/graph_revenue`,
          this.filter
        );
        respData = res.data.detail;
      }
      let label = [];
      var dataSet = [];

      if (this.filter.GraphType == 3) {
        label = await respData.map((el) =>
          moment(el.filter_date, "MM/DD/YYYY").format("DD/MM/YYYY")
        );
      } else {
        label = await respData.map((el) => el.filter_date);
      }

      var color = getRandomColor();
      dataSet.push(
        {
          label: "Total Revenue",
          type: "bar",
          group: true,
          data: [],
          backgroundColor: color.replace(")", ", 0.45)").replace("rgb", "rgba"),
        },
        {
          label: "Total Revenue From First Time Purchase Customer",
          type: "bar",
          data: [],
          group: true,
          backgroundColor: color,
        }
      );

      for (const x of respData) {
        dataSet[0].data.push(x.member_revenue);
        dataSet[1].data.push(x.new_member_revenue);
      }

      if (label.length === 1) {
        let fDate = await this.handleDateFormatLabel(
          this.filter.TypeDateFilter,
          label[0]
        );
        label = [fDate, ...label];
        await dataSet.forEach((ele) => {
          ele.data.unshift(0);
        });
      }

      this.chartList[1].label = label;
      this.chartList[1].data = dataSet;
      this.chartList[1].isFetch = false;
    },
    async getAge(has, data) {
      this.chartList[2].isFetch = true;
      var respData = data;
      if (!has) {
        const res = await this.$report.post(
          `/DashBoard/first_purchase/graph_age`,
          this.filter
        );
        respData = res.data.detail;
      }
      let label = [];
      var dataSet = [];
      let color = getRandomColor();

      label = await respData.map((el) => el.ageGroup);
      dataSet = [
        {
          label: "Number Of Customers",
          type: "bar",
          data: respData.map((el) => el.numberOfCustomer),
          borderColor: color,
          backgroundColor: color,
          fill: false,
        },
      ];

      this.chartList[2].label = label;
      this.chartList[2].data = dataSet;
      this.chartList[2].isFetch = false;
    },
    async getGender(has, data) {
      this.chartList[3].isFetch = true;
      var respData = data;
      if (!has) {
        const res = await this.$report.post(
          `/DashBoard/first_purchase/graph_gender`,
          this.filter
        );
        respData = res.data.detail;
      }
      let label = [];
      var dataSet = [];
      let color = getRandomColor();

      label = await respData.map((el) => el.gender);
      dataSet = [
        {
          label: "Number Of Customer",
          type: "bar",
          data: respData.map((el) => el.numberOfCustomer),
          borderColor: color,
          backgroundColor: color,
          fill: false,
        },
      ];
      this.chartList[3].label = label;
      this.chartList[3].data = dataSet;
      this.chartList[3].isFetch = false;
    },
    async getStat(has, data) {
      this.statLoading = true;
      var respData = data;
      if (!has) {
        const res = await this.$report.post(
          `/DashBoard/first_purchase/stat`,
          this.filter
        );
        respData = res.data.detail;
      }

      this.stat = respData;

      let stat = {
        totalCustomer: respData.member,
        percentCustomer: respData.percent_new_member,
      };
      let date =
        this.filter.LastType == 0
          ? `${this.$moment(this.filter.TransactionDateFrom).format(
              "DD MMM YYYY"
            )} - ${this.$moment(this.filter.TransactionDateTo).format(
              "DD MMM YYYY"
            )}`
          : this.$displayFilterDate(this.filter.LastType);
      this.$emit("setStat", stat, date);
      this.statLoading = false;
    },
    async getProduct() {
      this.product.isBusy = true;
      const res = await this.$report.post(`/Dashboard/first_purchase/product`, {
        customFilterRequest: {
          ...this.filter,
        },
        Search: this.product.filter.search,
        PageNumber: this.product.filter.page,
        RecordPerPage: this.product.filter.take,
        SortColumnId: this.product.filter.SortColumnId,
        SortType: this.product.filter.SortType,
      });

      this.product.items = res.data.detail.data;
      this.product.rows = res.data.detail.total;
      this.product.isBusy = false;
    },
    async getStore() {
      this.store.isBusy = true;
      const res = await this.$report.post(`/Dashboard/first_purchase/branch`, {
        customFilterRequest: {
          ...this.filter,
        },
        Search: this.store.filter.search,
        PageNumber: this.store.filter.page,
        RecordPerPage: this.store.filter.take,
        SortColumnId: this.store.filter.SortColumnId,
        SortType: this.store.filter.SortType,
      });

      this.store.items = res.data.detail.data;
      this.store.rows = res.data.detail.total;
      this.store.isBusy = false;
    },
    async getMemberTier() {
      this.member_tier.isBusy = true;
      const res = await this.$report.post(
        `/Dashboard/first_purchase/member_tier`,
        {
          customFilterRequest: {
            ...this.filter,
          },

          PageNumber: this.member_tier.filter.page,
          RecordPerPage: this.member_tier.filter.take,
          SortColumnId: this.member_tier.filter.SortColumnId,
          SortType: this.member_tier.filter.SortType,
        }
      );

      this.member_tier.items = res.data.detail;

      this.member_tier.isBusy = false;
    },
    paginationProduct(val) {
      this.product.filter.page = val;
      this.getProduct();
    },
    handleChangeTakeProduct(val) {
      this.product.filter.take = val;
      this.product.filter.page = 1;
      this.getProduct();
    },
    paginationStore(val) {
      this.store.filter.page = val;
      this.getStore();
    },
    handleChangeTakeStore(val) {
      this.store.filter.take = val;
      this.store.filter.page = 1;
      this.getStore();
    },
    async getProductFilter() {
      const res = await this.$report.post(
        `/RevenueReport/CustomProductFilter`,
        {
          SearchProduct: "",
          PageNumber: 1,
          RecordPerpage: 10,
        }
      );
      console.time();
      let product = await res.data.productFilterModels.map((el) => {
        return {
          id: el.id,
          productName: `${el.barcode} / ${el.productName}`,
        };
      });
      // this.$nextTick(async () => {
      // console.log(product);
      this.options.product = product;
      // setTimeout(() => {
      this.isFilterProduct = true;
      // }, 15000);
      // });
      // console.timeEnd();
    },
    async getFilter() {
      const res = await this.$report(`/RevenueReport/CustomFilter`);

      this.options = {
        ...res.data,
        product: [...this.options.product],
        dateType: [
          { text: "Last 7 Days", value: 7 },
          { text: "Last 30 Days", value: 30 },
          { text: "Last 365 Days", value: 365 },
          { text: "Custom", value: 0 },
        ],
      };
    },
    async exportFile(mode) {
      let body = {
        ...this.filter,
      };
      let url = `/Dashboard/first_purchase/export_report/`;
      let fileName = "";
      if (mode == 1) {
        url += `customer_date`;
        fileName = "Total-First-Time-Purchase-Customer";
      } else if (mode == 2) {
        url += `revenue_date`;
        fileName = "Total-Revenue";
      } else if (mode == 3) {
        url += `age_group`;
        fileName = "Age";
      } else if (mode == 4) {
        url += `gender_group`;
        fileName = "Gender";
      } else if (mode == 5) {
        url += `product`;
        fileName = "Product";
      } else if (mode == 6) {
        url += `branch`;
        fileName = "Store";
      } else if (mode == 7) {
        url += `member_tier`;
        fileName = "Member-Tiers";
      }
      this.$bus.$emit("showLoading");

      const res = await this.$report.post(url, body, {
        responseType: "blob",
      });

      let data = res;

      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", fileName + `.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.$bus.$emit("hideLoading");
    },
    async handleChartFilter(filter, chart) {
      let value = 0;
      if (filter == "Day") value = 3;
      if (filter == "Month") value = 2;
      if (filter == "Year") value = 1;
      if (filter == "Export") return this.exportFile(chart.exportMode);
      if (filter != "Export") chart.filterValue = filter;
      this.filter.TypeDateFilter = value;

      await chart.get();
    },
    clearFilter() {
      this.filter = {
        ChannelFilter: [],
        BranchFilter: [],
        AgeGroupFilter: [],
        GenderFilter: [],
        ProductFilter: [],
        MemberTierFilter: [],
        TransactionDateFrom: this.initFilterDate.TransactionDateFrom,
        TransactionDateTo: this.initFilterDate.TransactionDateTo,
        TypeDateFilter: 2,
        LastType: 2,
      };
      this.$store.dispatch("clearFilter");
      this.$refs.firstPurchaseFilter.hide();
      this.filterActions();
    },
    filterActions() {
      if (this.filter.LastType == 7) {
        this.TypeDateFilter = 3;
        this.filter.TransactionDateFrom = this.$moment()
          .subtract(1, "week")
          .format("YYYY-MM-DD");
        this.filter.TransactionDateTo = this.$moment().format("YYYY-MM-DD");
      } else if (this.filter.LastType == 30) {
        this.TypeDateFilter = 2;
        this.filter.TransactionDateFrom = this.$moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD");
        this.filter.TransactionDateTo = this.$moment().format("YYYY-MM-DD");
      } else if (this.filter.LastType == 365) {
        this.TypeDateFilter = 1;
        this.filter.TransactionDateFrom = this.$moment()
          .subtract(1, "years")
          .format("YYYY-MM-DD");
        this.filter.TransactionDateTo = this.$moment().format("YYYY-MM-DD");
      } else {
        this.TypeDateFilter = 0;
        if (this.filter.TransactionDateFrom)
          this.filter.TransactionDateFrom = this.$moment(
            this.filter.TransactionDateFrom
          ).format("YYYY-MM-DD");
        if (this.filter.TransactionDateTo)
          this.filter.TransactionDateTo = this.$moment(
            this.filter.TransactionDateTo
          ).format("YYYY-MM-DD");
      }

      this.getDefault();
    },
    handleSearchProduct() {
      this.getProduct();
    },
    handleSearchStore() {
      this.getStore();
    },
    handleInput(val) {
      this.filter.PurchaseTo = val;
    },
    clearExportFilter() {
      this.filterExportFile = {
        email: "",
      };
    },
    sortingChanged(ctx) {
      var index = 0;
      if (ctx.sortBy == "percenSalesContribution") {
        index = this.fields.product.findIndex((el) => el.key == "totalSales");

        index = index + 1;
      } else {
        index = this.fields.product.findIndex((el) => el.key == ctx.sortBy);
        index = index + 1;
      }

      if (
        this.product.filter.SortType &&
        !ctx.sortDesc &&
        this.product.filter.SortColumnId == index
      ) {
        this.product.filter.SortColumnId = 0;
        this.product.filter.sortBy = "";
        this.product.filter.sortDesc = "";
      } else {
        this.product.filter.SortColumnId = index;
        this.product.filter.SortType = ctx.sortDesc;
      }
      this.getProduct();
    },
    storeSorting(ctx) {
      let index = this.fields.store.findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;
      if (
        this.store.filter.SortType &&
        !ctx.sortDesc &&
        this.store.filter.SortColumnId == index
      ) {
        this.store.filter.SortColumnId = 0;
        this.store.filter.sortBy = "";
        this.store.filter.sortDesc = "";
      } else {
        this.store.filter.SortColumnId = index;
        this.store.filter.SortType = ctx.sortDesc;
      }
      this.getStore();
    },
    memberTierSorting(ctx) {
      let index = this.fields.member_tier.findIndex(
        (el) => el.key == ctx.sortBy
      );
      index = index + 1;
      if (
        this.member_tier.filter.SortType &&
        !ctx.sortDesc &&
        this.member_tier.filter.SortColumnId == index
      ) {
        this.member_tier.filter.SortColumnId = 0;
        this.member_tier.filter.sortBy = "";
        this.member_tier.filter.sortDesc = "";
      } else {
        this.member_tier.filter.SortColumnId = index;
        this.member_tier.filter.SortType = ctx.sortDesc;
      }
      this.getMemberTier();
    },
  },
};
</script>

<style>
.text-highlight {
  color: var(--primary-color);
  font-weight: bold;
}
.text-gray {
  color: whitesmoke;
}
.chart-title-name {
  font-size: 18px;
  font-weight: bold;
  color: black;
  /* margin-bottom: 0.75rem; */
}

.input-select .select-custom label {
  color: var(--font-main-color);
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
</style>
