<template>
  <div class="bg-white pt-3">
    <SideBarFilter
      ref="filter"
      :filter="filter"
      :placeholder="'Search Name'"
      @clearFilter="clearFilter"
      @searchAll="filterActions"
      :hideStatusFilter="false"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <FilterCustomDate
          customText="Connect Date"
          id="7"
          :from="filter.DateFrom"
          :to="filter.DateTo"
          :type="filter.LastType"
          @update:type="(value) => (filter.LastType = value)"
          @update:from="(value) => (filter.DateFrom = value)"
          @update:to="(value) => (filter.DateTo = value)"
          :useStore="false"
        />
        <FilterCustomDate
          customText="Register Date"
          id="7"
          :from="filter.RegisterDateFrom"
          :to="filter.RegisterDateTo"
          :type="filter.LastTypeRegister"
          @update:type="(value) => (filter.LastTypeRegister = value)"
          @update:from="(value) => (filter.RegisterDateFrom = value)"
          @update:to="(value) => (filter.RegisterDateTo = value)"
          :useStore="false"
        />

        <div class="mb-3">
          <MultiSelect
            used="tier"
            textFloat="Member tier"
            :options="options.memberTiers"
            textField="memberTierName"
            valueField="id"
            placeholder="Select Member tier"
            v-model="filter.MemberTierFilter"
            :value="filter.MemberTierFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            used="channel"
            textFloat="Channel"
            :options="options.channelTypes"
            textField="channelName"
            valueField="id"
            placeholder="Select Channel"
            v-model="filter.ChannelFilter"
            :value="filter.ChannelFilter"
          />
        </div>
      </template>
    </SideBarFilter>
    <b-container>
      <b-row style="row-gap: 15px">
        <b-col cols="12" md="6">
          <b-card class="mb-0 h-100 shadow-sm border-none">
            <div class="chart-title-name mb-3">Profile Completeness</div>

            <b-progress
              :value="profileComplete.customer_completed"
              :max="profileComplete.total_customer"
              class="mb-3"
              :style="{
                '--display': `${
                  profileComplete.customer_completed > 0 ? 'flex' : 'none'
                }`,
              }"
            ></b-progress>

            <b-row class="align-items-center justify-content-between">
              <b-col md="6" class="text-primary font-bold">Overall</b-col>
              <b-col md="6" xl="4" class="text-right text-primary">
                <b-row no-gutters>
                  <b-col cols="6" class="text-right mx-auto">
                    {{ Math.floor(profileComplete.percent_complted) }}%
                  </b-col>
                  <b-col cols="6" class="text-right text-primary ml-auto">
                    <span
                      >({{
                        profileComplete.customer_completed | numeral("0,0")
                      }}/{{
                        profileComplete.total_customer | numeral("0,0")
                      }})</span
                    ></b-col
                  >
                </b-row>
              </b-col>
            </b-row>
            <hr />
            <b-row
              class="row-gap-lg"
              style="max-height: 500px; overflow-y: auto"
            >
              <b-col cols="6" class="text-primary">Overall</b-col>
              <b-col
                cols="6"
                xl="4"
                :key="'field_value' + fields.field_name"
                class="ml-auto"
              >
                <b-row no-gutters>
                  <b-col cols="6" class="text-right ml-auto text-primary">
                    {{ Math.floor(profileComplete.percent_complted) }}%
                  </b-col>
                  <b-col cols="6" class="text-right text-primary ml-auto">
                    <span
                      >({{
                        profileComplete.customer_completed | numeral("0,0")
                      }}/{{
                        profileComplete.total_customer | numeral("0,0")
                      }})</span
                    ></b-col
                  >
                </b-row>
              </b-col>

              <template v-for="fields of profileComplete.profile_detail">
                <b-col cols="6" :key="'field_name' + fields.field_name">
                  {{ fields.field_name }}
                </b-col>
                <b-col
                  cols="6"
                  xl="4"
                  :key="'field_value' + fields.field_name"
                  class="ml-auto"
                >
                  <b-row no-gutters>
                    <b-col cols="6" class="text-right ml-auto">
                      {{ Math.floor(fields.percent_completed) }}%
                    </b-col>
                    <b-col cols="6" class="text-right ml-auto">
                      ({{ fields.customer_completed | numeral("0,0") }}/{{
                        profileComplete.total_customer | numeral("0,0")
                      }})
                    </b-col>
                  </b-row>
                </b-col>
              </template>
            </b-row>
          </b-card>
        </b-col>
        <template v-for="(chart, index) in chartList">
          <b-col
            cols="12"
            md="6"
            :key="index"
            class="resize"
            v-if="chart.isChart"
          >
            <b-card
              class="mb-0 h-100 shadow-sm border-none text-black rounded-lg"
            >
              <div>
                <div class="d-flex justify-content-between mb-3">
                  <div class="chart-title-name">{{ chart.name }}</div>
                  <div class="d-flex" style="column-gap: 5px">
                    <div
                      :class="`f-14 underline cursor-pointer`"
                      v-for="chartFilter in chart.filter"
                      :key="chartFilter + '-' + chart.name"
                      @click="handleChartFilter(chartFilter, chart)"
                    >
                      <span
                        :class="`${
                          chart.filterValue == chartFilter ||
                          chartFilter == 'Export'
                            ? 'text-highlight'
                            : 'text-gray'
                        }`"
                        >{{ chartFilter }}</span
                      >
                    </div>
                  </div>
                </div>

                <template v-if="chart.isFetch">
                  <ChartLoading :id="'loading-' + chart.name" type="line" />
                </template>
                <template v-else>
                  <BasketChart
                    :id="chart.name"
                    :chartData="chart.data"
                    :text="chart.text"
                    class="mt-auto"
                    :hideLegend="chart.hideLegend"
                    :label="chart.label"
                    :type="chart.type"
                    :filterValue="chart.filterValue"
                    :xText="chart.xText"
                    :isStacked="chart.isStacked"
                  />
                </template>
              </div>
            </b-card>
          </b-col>
        </template>

        <b-col cols="12">
          <b-card class="shadow-sm border-none text-black rounded-lg">
            <div class="mb-2 d-flex justify-content-between">
              <div class="chart-title-name">{{ chartList[1].name }}</div>
              <div class="d-flex align-items-center" style="column-gap: 5px">
                <div
                  class="f-14 underline cursor-pointer"
                  v-for="chartFilter of chartList[1].filter"
                  :key="chartFilter + '-' + chartList[1].name"
                  @click="handleChartFilter(chartFilter, chartList[1])"
                >
                  <span class="text-highlight">{{ chartFilter }}</span>
                </div>
              </div>
            </div>
            <div class="shadow-sm mb-3">
              <b-table
                fixed
                striped
                hover
                :fields="fields.channel"
                :items="channel.items"
                :busy="channel.isBusy"
                show-empty
                empty-text="No matching records found"
              >
                <template v-slot:cell(total_member)="data">
                  <span>
                    {{ data.item.total_member | numeral("0,0") }}
                  </span>
                </template>
              </b-table>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import ChartLoading from "@/components/loading/ChartLoading";

import BasketChart from "./BasketChart";
import Multiselect from "vue-multiselect";

function getRandomColor() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return "rgb(" + r + "," + g + "," + b + ")";
}
export default {
  components: {
    Multiselect,

    ChartLoading,
    BasketChart,
  },
  data() {
    return {
      isLoading: true,
      filter: {
        DateFrom: this.$store.state.filter.from,
        DateTo: this.$store.state.filter.to,
        RegisterDateFrom: "",
        RegisterDateTo: "",
        TypeDateFilter: 0, // 1 = Year , 2 = Month , 3 = Day

        LastType: this.$store.state.filter.dateType,

        LastTypeRegister: 2,
        ChannelFilter: this.$store.state.filter.channel, // list int
        MemberTierFilter: this.$store.state.filter.tier,
      },

      options: {
        memberTiers: [],
        channelTypes: [],
        dateType: [
          { text: "Last 7 Days", value: 7 },
          { text: "Last 30 Days", value: 30 },
          { text: "Last 365 Days", value: 365 },
          { text: "Custom", value: 0 },
        ],
      },
      chartList: [
        {
          name: "Connect Channel",
          filterValue: "Month",
          text: "Total Member",
          filter: ["Day", "Month", "Year", "Export"],
          label: [],
          data: [],
          export: "report/date",
          get: () => this.getConnectChannelChart(),
          type: "bar",
          isFetch: false,
          isChart: true,
        },
        {
          name: "Connect Channel",
          get: () => this.getConnectChannelList(),
          key: "channel",
          export: "report",
          filterValue: "",
          filter: ["Export"],
        },
        {
          name: "Total Connect Channel",
          get: () => this.getTotalCustomer(),
          key: "channel",
          export: "report",
          filterValue: "",
          filter: ["Export"],
        },
      ],
      fields: {
        channel: [
          {
            key: "channel_name",
            label: "Channel Name",
            class: "w-6",
            sortable: true,
            text: "Total",
          },
          {
            key: "total_member",
            label: "Total Member",
            thClass: "w-6",
            sortable: true,
          },
        ],
        totalCustomer: [
          {
            key: "channel_name",
            label: "Channel Name",
            class: "w-6",
            sortable: true,
            text: "Total",
          },
          {
            key: "total_member",
            label: "Total Member",
            thClass: "w-6",
            sortable: true,
          },
        ],
      },
      channel: {
        items: [],
        isBusy: false,
        rows: 0,
        filter: {
          SortColumnId: 0,
          SortType: true,
          sortBy: "",
          sortDesc: "",
        },
      },
      totalCustomer: {
        items: [],
        isBusy: false,
        rows: 0,
        filter: {
          SortColumnId: 0,
          SortType: true,
          sortBy: "",
          sortDesc: "",
        },
      },
      stat: {},
      isFilterProduct: false,
      initFilterDate: {
        MinDate: "",
        MaxDate: "",
      },
      profileComplete: {
        customer_completed: 0,
        total_customer: 0,
        percent_complted: 0,
        profile_detail: [],
      },
    };
  },
  async mounted() {
    this.initDateFilter();
    await this.getFilter();
    await this.getData();
    this.isLoading = false;
  },
  methods: {
    async getData() {
      return await Promise.all([
        this.getProfileComplete(),
        this.getStat(),
        // this.getTotalCustomer(),
        this.getConnectChannelChart(),
        this.getConnectChannelList(),
        this.getTotalCustomer(),
      ]);
    },
    handleDateFormat(type, date) {
      let value = "";
      if (type === 2)
        value = moment(date, "MM/YYYY").format("YYYY-MM-DDT00:00:00");
      else value = moment(date).format("YYYY-MM-DDT00:00:00");
      return value;
    },
    handleDateFormatLabel(type, date) {
      let value = "";
      if (type === 1) value = moment(date).format("YYYY");
      else if (type === 2) value = moment(date).format("MM/YYYY");
      else value = moment(date, "DD/MM/YYYY").format("DD/MM/YYYY");
      return value;
    },
    initDateFilter() {
      let toDay = moment().format("YYYY-MM-DDT00:00:00");
      // this.initFilterDate.MinDate = moment(toDay).day(-365).format();
      this.initFilterDate.MaxDate = toDay;
    },
    async getConnectChannelChart() {
      this.chartList[0].isFetch = true;
      const res = await this.$report.post(
        `/dashboard/customer_connect/date`,
        this.filter
      );

      let label = [];
      var dataSet = [];
      let dataList = [];

      label = (await res.data.detail.map((el) => el.group_date)) || [];

      if (res.data.detail.length > 0) {
        await res.data.detail[0].data.forEach((element, index) => {
          let color = getRandomColor();
          dataSet.push({
            order: index,
            label: element.channel_name,
            data: [],
            borderColor: color,
            backgroundColor: color,
            fill: false,
          });
        });

        if (this.filter.TypeDateFilter == 1) {
          dataList.map(
            (el) =>
              (el.group_date = this.handleDateFormat(
                this.filter.TypeDateFilter,
                el.group_date
              ))
          );
        }

        for (const val of dataSet) {
          let tempObj = [];

          for (const list of res.data.detail) {
            let data = list.data.find((el) => el.channel_name == val.label);
            tempObj.push(data.total_member);
          }
          val.data = tempObj;
        }
      }

      // let labelChart = label.map((el) =>
      //   this.handleDateFormatLabel(this.filter.TypeDateFilter, el)
      // );
      this.chartList[0].label = label;
      this.chartList[0].data = dataSet;
      this.chartList[0].isFetch = false;
    },
    async getProfileComplete() {
      const res = await this.$report.post(
        `/dashboard/customer_connect/profile_completed`,
        this.filter
      );
      console.log(res);
      this.profileComplete = res.data.detail;
    },
    async getStat() {
      let date =
        this.filter.LastType == 0
          ? `${this.$moment(this.filter.DateFrom).format(
              "DD MMM YYYY"
            )} - ${this.$moment(this.filter.DateTo).format("DD MMM YYYY")}`
          : this.$displayFilterDate(this.filter.LastType);
      console.log(date);
      this.$emit("setStat", null, date);
    },
    async getConnectChannelList() {
      this.channel.isBusy = true;
      const res = await this.$report.post(`/dashboard/customer_connect`, {
        ...this.filter,
      });

      this.channel.items = res.data.detail;
      this.channel.rows = this.channel.items.length;
      this.channel.isBusy = false;
    },
    async getTotalCustomer() {
      this.totalCustomer.isBusy = true;
      const res = await this.$report.post(
        `/dashboard/customer_connect/analysis`,
        {
          ...this.filter,
        }
      );
      let stat = {
        totalCustomer: res.data.detail.total_customer,
        percentCustomer: res.data.detail.percent_customer,
      };
      let date =
        this.filter.LastType == 0
          ? `${this.$moment(this.filter.DateFrom).format(
              "DD MMM YYYY"
            )} - ${this.$moment(this.filter.DateTo).format("DD MMM YYYY")}`
          : this.$displayFilterDate(this.filter.LastType);
      this.$emit("setStat", stat, date);
      // this.totalCustomer.items = res.data.detail;
      // this.totalCustomer.rows = this.totalCustomer.items.length;
      // this.totalCustomer.isBusy = false;
    },
    async getFilter() {
      const res = await this.$report(`/dashboard/Filter/CustomerConnect`);

      this.options = {
        channelTypes: res.data.detail.channel,
        memberTiers: res.data.detail.member_tier,
        dateType: [
          { text: "Last 7 Days", value: 7 },
          { text: "Last 30 Days", value: 30 },
          { text: "Last 365 Days", value: 365 },
          { text: "Custom", value: 0 },
        ],
      };
    },
    async export(path) {
      this.$bus.$emit("showLoading");
      const res = await this.$report.post(
        `/dashboard/customer_connect/${path}`,
        this.filter,
        {
          responseType: "blob",
        }
      );

      let data = res;

      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", path + `.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.$bus.$emit("hideLoading");
    },
    async handleChartFilter(filter, chart) {
      let value = 0;
      if (filter == "Day") value = 3;
      if (filter == "Month") value = 2;
      if (filter == "Year") value = 1;
      if (filter == "Export") return this.export(chart.export);
      if (filter != "Export") chart.filterValue = filter;
      this.filter.TypeDateFilter = value;
      this.filter.TypeDateFilter = value;
      await chart.get();
    },
    async clearFilter() {
      this.filter = {
        DateFrom: "",
        DateTo: "",
        RegisterDateFrom: "",
        RegisterDateTo: "",
        TypeDateFilter: 0, // 1 = Year , 2 = Month , 3 = Day
        LastType: 2,

        LastTypeRegister: 2,
        ChannelFilter: [], // list int
        MemberTierFilter: [], // list int
      };

      this.chartList.forEach((chart) => {
        if (chart.isChart) chart.filterValue = "Month";
      });
      this.$store.dispatch("clearFilter");
      this.$refs.filter.hide();
      this.filterActions();
    },
    filterActions() {
      if (this.filter.DateFrom)
        this.filter.DateFrom = this.$moment(this.filter.DateFrom).format(
          "YYYY-MM-DD"
        );
      if (this.filter.DateTo)
        this.filter.DateTo = this.$moment(this.filter.DateTo).format(
          "YYYY-MM-DD"
        );

      this.getData();
    },
  },
};
</script>

<style>
.text-highlight {
  color: var(--primary-color);
  font-weight: bold;
}
.text-gray {
  color: whitesmoke;
}
.chart-title-name {
  font-size: 18px;
  font-weight: bold;
  color: black;
  /* margin-bottom: 0.75rem; */
}

.input-select .select-custom label {
  color: var(--font-main-color);
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.progress {
  height: 10px;
  position: relative;
  border: 1px solid #c9c9c9;
  background-color: white;
  overflow: visible;
}
.progress-bar {
  position: relative;

  overflow: unset;

  background-color: var(--primary-color);
}
.progress-bar::after {
  content: "\2713";
  position: absolute;
  right: -5px;
  width: 20px;
  border: 2px solid white;
  height: 20px;
  background-color: rgb(4, 180, 4);
  border-radius: 50%;
  padding: 0.25rem;
  text-align: center;
  display: var(--display);
  align-items: center;
  justify-content: center;
}
.field-list {
  max-height: 300px;
  overflow: auto;
}
</style>
